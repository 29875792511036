.period-text {
    font-size: 16px;
    color: #000;
    font-weight: 500;
}

.date-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    // margin-top: -72px;
    padding-right: 28;
}

.filter-date-div {
    padding-bottom: 20;
    border-bottom: 1px solid #d4d5d8;
}

.user-submission-div {
    padding: 38px 0 20px 0;
    width: 100%;
}

.card-style {
    width: 20vw;
    padding: 20px 16px;
    background-color: #fff;
    border: 1px solid #E6EAF0;
    height: 100%;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    color: $mainTextColor;

    .card-title {
        display: flex;
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
    }

    .card-text {
        font-size: 16px;
        line-height: 24px;
    }
}

.see-more {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: $adminColor;
    text-decoration: underline;
}

.modal-flex {
    display: flex;
    width: 300px;
}

.card-progress {
    background-color: #fff;
    text-align: center;
    padding-top: 20%;
    height: 20vh;
    width: 11vw;
}

.card-table-div {
    padding: 32px;
    background-color: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
}