.channel-name-and-code p {
    margin: 0px;
}

.channel-name-and-code p:first-child {
    font-weight: 700;
}

.primary-btn,
.primary-btn:hover,
.primary-btn:active,
.primary-btn:focus {
    background-color: #0A315E;
    border: 1px solid #0A315E;
}

.button-container {
    display: flex;
    justify-content: space-between !important;
    gap: 1em;
}

.merchant-id-settings-container {
    padding: 20px 0px;
    display: flex;
    gap: 1em;
}

.merchant-id-settings-container .ant-input-affix-wrapper {
    width: 450px;
    height: 40px;
}

.merchant-id-settings-container .date-picker div input {
    height: 40px;
}

@media (max-width: 768px) {
    .merchant-id-settings-container {
        flex-direction: column;
    }

    .merchant-id-settings-container .ant-input-affix-wrapper {
        width: 100%;
    }

    .merchant-id-settings-container button {
        width: 100%;
    }
}

@media (max-width: 600px) {
    .merchant-id-settings-card .ant-card-body div div:nth-child(2) {
        flex-direction: column;
        gap: 1em;
    }

    .merchant-id-settings-card .ant-card-body div div:nth-child(2) input {
        width: 100% !important;
    }

    .button-container,
    .button-container div {
        flex-direction: column;
    }
}
