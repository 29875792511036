.drawer {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.drawer.open {
  display: block;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.drawer-content {
  position: absolute;
  top: 0;
  right: -100%;
  width: 480px;
  height: 100%;
  background-color: #fff;
  transition: right 3s ease-in-out;

  display: flex;
  flex-direction: column;
}

.drawer.open .drawer-content {
  right: 0;
}

.drawer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #ccc;
}

.close-btn {
  background: none;
  border: none;
  cursor: pointer;
}

.drawer-body {
  padding: 1rem;
  flex: 1;
  overflow-y: auto;
  margin-bottom: 1rem;
}

.drawer-footer {
  padding: 1rem;
  border-top: 1px solid #ccc;
}
