@import '../main.scss';

.web-div {
    padding: 60px 0px 30px 0px;
    width: 55vw;
    margin-left: -52px;
}

.mobile-div {
    padding: 16px;
    background: #E5E5E5;
}

.card-hiw {
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 40px;

    @media (max-width: 600px) {
        padding: 20px;
        margin: 16px;
    }
}

.step-div {
    background: #F5922F;
    border-radius: 10px;
    padding: 8px;
    font-weight:  700;
    color: #ffff;
    font-size: 16px;
    // margin:auto;
    width: 67px;

    @media (max-width: 600px) {
        font-size: 12px;
        width: 59px;

    }
}

.step-div-2 {
    background: #F5922F;
    border-radius: 10px;
    padding: 8px;
    font-weight:  700;
    color: #ffff;
    font-size: 12px;
    // margin:auto;
    width: 67px;

}

.play-video {
    background: #2B2D32;
    box-shadow: 0px 8px 24px rgba(0, 35, 11, 0.12);
    border-radius: 10px;
    border: 1px solid #2B2D32;
    width: 400px;
    height : 260px;
    cursor: pointer;

    @media (max-width: 600px) {
        width: 280px;
        height: 200px;

    }
}

.link-text {
    font-size: 16px;
    font-style: italic;
    word-wrap: break-word;

    @media (max-width: 1260px) {
        font-size: 12px;
    }
}