.cashbux-recon-table table {
    width: max-content !important;
}

.cashbux-recon-tab-btn {
    padding: 12px 18px 13px 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    height: 40px;
    color: #2b2d33;
    background-color: #ffffff;
}

.cashbux-recon-tab-btn:hover {
    color: #40a9ff;
}

.cashbux-recon-tab-btn.active {
    color: #ffffff;
    background-color: #40a9ff;
    border-color: #40a9ff;
}

.recon-header-buttons {
    margin-bottom: 16px;
    display: flex; 
    justify-content: space-between;
}

.recon-filter {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
    align-items: center;
}

.recon-filter .ant-divider {
    height: 35px;
    width: 1px;
    background-color: #000;
    margin: 0px;
}

.update-transaction-btn {
    display: block;
    margin-bottom: 16px;
}

@media (max-width: 960px) {
    .recon-header-buttons {
        height: 40px;
        align-items: center;
    }
}

@media (max-width: 768px) {
    .recon-header-buttons {
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        margin-bottom: 0px;
    }

    .recon-filter {
        flex-direction: column;
        align-items: normal;
    }

    .recon-filter .ant-divider {
        display: none;
    }
    
    .recon-filter .date-pic-hyphen {
        display: none;
    }

    .update-transaction-btn {
        margin-bottom: 0px;
    }
}
