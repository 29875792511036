@import '../main.scss';

@mixin btn-main() {
    background-color: $adminColor;
    border: 1px solid $adminColor;
    padding: 0px 12px;
    height: 32px;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    cursor: pointer;

    &:hover {
        background-color: $adminSubColor;
        border: 1px solid $adminSubColor;
        cursor: pointer;
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

@mixin btn($theme: 'nil') {
    @include btn-main;
    @if $theme == 'me_' {
        background-color: $buxMeColor;
        border: 1px solid $buxMeColor;

        &:hover {
            background-color: $buxMeSubColor;
            border: 1px solid $buxMeSubColor;
        }
    }

    @else if $theme == 'gig' {
        background-color: $buxGigColor;
        border: 1px solid $buxGigColor;

        &:hover {
            background-color: $buxGigSubColor;
            border: 1px solid $buxGigSubColor;
        }
    }

    @else if $theme == 'sme' {
        background-color: $buxSmeColor;
        border: 1px solid $buxSmeColor;

        &:hover {
            background-color: $buxSmeSubColor;
            border: 1px solid $buxSmeSubColor;
        }
    }

    @else if $theme == 'biz' {
        background-color: $buxBizColor;
        border: 1px solid $buxBizColor;

        &:hover {
            background-color: $buxBizSubColor;
            border: 1px solid $buxBizSubColor;
        }
    }

    @else if $theme == 'nil' {
        background-color: $adminColor;
        border: 1px solid $adminColor;

        &:hover {
            opacity: 0.8;
            // background-color: $adminSubColor;
            // border: 1px solid $adminSubColor;
        }
    }
}

.btn--nil {
    @include btn("nil");
}

.btn--me_ {
    @include btn('me_');
}

.btn--gig {
    @include btn('gig');
}

.btn--sme {
    @include btn('sme');
}

.btn--biz {
    @include btn('biz');
}

@mixin btn-outline() {
    background-color: transparent;
    border: 1px solid $adminColor;
    height: 32px;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    padding: 0px 12px;
    cursor: pointer;

    &:hover {
        opacity: 0.9;
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

@mixin outline-btn($theme: 'nil') {
    @include btn-outline;
    @if $theme == 'me_' {
        color: $buxMeColor;
        border: 1px solid $buxMeColor;

        // &:hover {
        //     color: #fff;
        //     background-color: $buxMeColor;
        //     border: 1px solid $buxMeColor;
        // }
    }

    @else if $theme == 'gig' {
        color: $buxGigColor;
        border: 1px solid $buxGigColor;

        // &:hover {
        //     color: #fff;
        //     background-color: $buxGigColor;
        //     border: 1px solid $buxGigColor;
        // }
    }

    @else if $theme == 'sme' {
        color: $buxSmeColor;
        border: 1px solid $buxSmeColor;

        // &:hover {
        //     color: #fff;
        //     background-color: $buxSmeColor;
        //     border: 1px solid $buxSmeColor;
        // }
    }

    @else if $theme == 'biz' {
        color: $buxBizColor;
        border: 1px solid $buxBizColor;

        // &:hover {
        //     color: #fff;
        //     background-color: $buxBizColor;
        //     border: 1px solid $buxBizColor;
        // }
    }

    @else if $theme == 'nil' {
        color: $adminColor;
        border: 1px solid $adminColor;

        // &:hover {
        //     color: #fff;
        //     background-color: $adminColor;
        //     border: 1px solid $adminColor;
        // }
    }
}

.outline-btn--nil {
    @include outline-btn("nil");
}

.outline-btn--me_ {
    @include outline-btn("me_");
}

.outline-btn--gig {
    @include outline-btn("gig");
}

.outline-btn--sme {
    @include outline-btn("sme");
}

.outline-btn--biz {
    @include outline-btn("biz");

    div {
        .right-32 {
            width: 290px;
            display: block;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
}

@mixin btn-complete() {
    width: 100%;
    height: 28px;
    background-color: $adminColor;
    color: #fff;
    border: 1px solid $adminColor;
    border-radius: 24px;
    font-size: 14px;
    font-weight: bold;
}

@mixin complete-btn($theme: "nil") {
    @include btn-complete;
    @if $theme == 'me_' {
        background-color: $buxMeColor;
        border: 1px solid $buxMeColor;
    }

    @else if $theme == 'gig' {
        background-color: $buxGigColor;
        border: 1px solid $buxGigColor;
    }

    @else if $theme == 'sme' {
        background-color: $buxSmeColor;
        border: 1px solid $buxSmeColor;
    }

    @else if $theme == 'biz' {
        background-color: $buxBizColor;
        border: 1px solid $buxBizColor;
    }

    @else if $theme == 'nil' {
        background-color: $adminColor;
        border: 1px solid $adminColor;
    }
}

.complete-btn--nil {
    @include outline-btn("nil");
}

.complete-btn--me_ {
    @include complete-btn("me_");
}

.complete-btn--gig {
    @include complete-btn("gig");
}

.complete-btn--sme {
    @include complete-btn("sme");
}

.complete-btn--biz {
    @include complete-btn("biz");
}

.big-btn {
    width: 100%;
    height: 48px;
    color: #fff;
    font-weight: 600;
}

.md-btn {
    border-radius: 4px;
    height: 32px;
    font-size: 0.7vw;
    color: #fff;
    cursor: pointer;
}

.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}


.menuBtn {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: 0; 

    .menuState {
        background:#E9EEF4; 
        border-radius: 50px; 
        height: 100%; 
        padding: 8px;
    }
}

.graphBtn {
    height: 40px;
    width: 60px;
    border: 1px solid #eeeeee;
}


.payout-btn {
    width: 100%;
    height: 48px;
    background-color: #fff;
    font-weight: bold;
    color: $mainTextColor;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    border: 0;
    cursor: pointer;
    padding: 12px 16px;

    @media (max-width: 1260px) {
        padding: 0px 16px;
    }
}

.admin-btn-pill {
    font-size: 12px;
    font-weight: bold !important;
    text-align: center;
    height: 40px !important;
    padding: 0 32px;
    margin-right: 8px;
    border: 1px solid #D4D5D8 !important;
    border-radius: 50px !important;
    color: $mainTextColor;

}

.admin-top-btn {
    height: 40px !important;
    padding: 0px 12px;
    padding: 12px;
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    border-radius: 4px 0px 0px 4px;
    font-weight: 700 !important;

}

.selected-value {
    color: #fff !important;
    background-color: #0D3D76 !important;
}

.not-selected-value {
    color: $mainTextColor !important;
    background-color: #fff !important;
}

.disabled-btn {
    background-color: #f5f5f5 !important;
}

.neutral-value {
    background-color: #eeeeee !important;
}

.admin-download-btn {
    height: 40px !important;
    border: 1px solid $adminColor !important;
    box-sizing: border-box;
    border-radius: 4px;
    color: $adminColor !important;
    font-weight: 500 !important;
    background-color: transparent !important;
    cursor: pointer;
}

.clear-filter-btn {
    height: 40px;
    border-radius: 20px;
    border: 1px solid #d4d5d8;
    box-sizing: border-box;
    color: rgba(43, 45, 50, 0.8);
    background-color: #E9EEF4;
    width: 120px;
    font-weight: 500;

    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

// .clear-filter-btn:hover {
//     background-color: $adminColor;
//     color: #fff;
// }

.admin-apply-btn {
    background-color: $adminColor;
    border: 1px solid $adminColor;
    color: #fff;
    border-radius: 4px;
    font-weight: 500px;
    cursor: pointer;
    width: 100px;
    height: 40px;
}

.admin-disabled-btn {
    opacity: 0.5;
    cursor: not-allowed;
}

.add-user {
    background-color: $adminColor !important;
    border: 1px solid $adminColor !important;
    color: #fff !important;
    border-radius: 4px !important;
    font-weight: bold !important;
    cursor: pointer !important;
    width: 120px !important;
    height: 40px !important;
}


.normal-button-tab {
    background-color: $adminColor !important;
    border: 1px solid $adminColor !important;
    color: #fff !important;
    border-radius: 4px !important;
    font-weight: bold !important;
    cursor: pointer !important;
}

.not-selected-button-tab {
    background-color: #fff !important;
    border: 1px solid #fff !important;
    color: $mainTextColor !important;
    border-radius: 4px !important;
    font-weight: bold !important;
    cursor: pointer !important;
}

.send-button {
    height: 48px;
    border: 1px solid $adminColor;
    background-color: $adminColor;
    color: #FFF;
    font-size: 14px;
    font-weight: 600;
    border-radius: 4px;
    width: 100%;
}

.filter-user-btn {
    height: 40px !important;
    border: 1px solid #B7B9C0;
    background-color: #fff;
    color: $mainTextColor;
    border-radius: 4px;
    box-sizing: border-box;
    opacity: 0.8;
    cursor: pointer;
    width: 150px;
}

.filter-user-dropdown-btn {
    // z-index: 5; 
    height: 40px !important; 
    width: 100%;
    padding-top: 5px; 
    margin-right: 12px;
    color: #000;
    border: 1px solid #c5cacf;
}

.btn-height {
    height: 40px;
}

.btn-small-height {
    height: 32px;
}

.btn-full-width {
    width: 10vw !important;
    @media (max-width: 1360px) {
        width: 200px !important;
    }
}

.btn-100-width {
    width: 100%;
}

.btn-width {
    width: 120px !important;
}

.btn-align-left {
    text-align: left !important;
}

.btn-web-width {
    width: 98% !important;
}

.btn-mobile-width {
    width: 100% !important;
}

.btn-padding {
    padding: 8px 16px;
}

.submit-btn-bux {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #fafafa;
    background: #f5922f;
    border: 1px solid #f5922f;
    border-radius: 4px;
    height: 40px;
    width: 100%;
    margin-top: 16px;
    cursor: pointer;

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

.button-select-style {
    height: 40px;
    background-color: #fff;
    border: 1px solid #D4D5D8;
    border-radius: 4px;
    opacity: 0.8;
    cursor: pointer;
    width: 240px;
}

.checkout-channel-card-wrapper {
    // max-width: 100%;
    width: 100%;
    margin: '0 6px 12px 0px';
    display: grid;
    grid-gap: 1.5rem;
    column-gap: 14px;

    @media (min-width: 400px) {
        grid-template-columns: repeat(2, 1fr);
        margin: '0 6px 12px 0px';
     }

    @media (min-width: 600px) {
       grid-template-columns: repeat(3, 1fr);
       margin: '0 6px 12px 0px';
    }

    @media (min-width: 900px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1200px) {
        grid-template-columns: repeat(4, 1fr);
        margin: '0 6px 12px 0px';
    }

    @media (min-width: 1500px) {
        grid-template-columns: repeat(5, 1fr);
    }

    @media (min-width: 2000px) {
        grid-template-columns: repeat(8, 1fr);
    }
}

.checkout-channel-card {
    height: 4rem;
    width: 100%;
}

.otc-button-selected {
    outline: 0;
    border: 2px solid #F5922F;
    box-sizing: border-box;
    border-radius: 8px;
    background: #FDF4E3;
    width: 100%;
    position: relative;
    height: 74px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4px;
    // padding-top: 20px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.48);

    // @media (min-width: 960px) {
    //     width: 50vw;
    // }
    // @media (min-width: 1278px) {
    //     width: 30vw;
    // }
}

.otc-button-not-selected {
    border: 1px solid #D4D5D8;
    box-sizing: border-box;
    border-radius: 8px;
    background: #fff;
    width: 100%;
    height: 74px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4px;
    // padding-top: 20px;
    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        background-color: #EEEEEE;
    }
    // @media (min-width: 960px) {
    //     width: 50vw;
    // }
    // @media (min-width: 1278px) {
    //     width: 30vw;
    // }
}

.ol-ew-btn-not-selected {
    border: 1px solid #D4D5D8;
    box-sizing: border-box;
    border-radius: 8px;
    background: #fff;
    // padding-top: 20px;
    width: 100%;
    height: 74px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4px;
    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        background-color: #EEEEEE;
    }


}

.ol-ew-btn-selected {
    outline: 0;
    border: 2px solid #F5922F;
    box-sizing: border-box;
    border-radius: 8px;
    // padding-top: 20px;
    background: #FDF4E3;
    width: 100%;
    height: 74px;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 4px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.48);
}

.add-money-ol-ew-btn-not-selected {
    border: 2px solid #e6eaf0;
    box-sizing: border-box;
    border-radius: 10px;
    background: #fff;
    padding-top: 20px;
    width: 100%;
    height: 100%;
    position: relative;
    cursor: pointer;
    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }


}

.add-money-ol-ew-btn-selected {
    outline: 0;
    border: 2px solid #1DD28B;
    box-sizing: border-box;
    border-radius: 10px;
    background: #fff;
    padding-top: 20px;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    position: relative;
    cursor: pointer;
}

.pill-btn {
    border-radius: 80px;
}

.qr-btn {
    width: 105px;
    height: 42px;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: #FFFFFF;
    border: 1px solid #d4d5d8;
    font-weight: 400;
    color: #000000;
    cursor: pointer;
    &:hover {
        background-color: #f5f5f4;
    }

    @media (min-width: 320px) and (max-width: 820px) {
        
    }
}

.qr-btn-selected {
    width: 105px;
    height: 42px;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: #f5922f;
    border: 1px solid #f5922f;
    font-weight: 600;
    color: #ffff;
    cursor: pointer;
}

.qr-social-btn {
    outline: 0;
    background: transparent;
    border: 0;
    cursor: pointer;
    &:hover {
        transform: scale(1.2);
    }   
}

.group-btn {
    background: #fff;
    border: 1px solid #eeeeee;
    height: 40px;
    font-weight: 600;
    color: #2b2d32;
    padding: 0px 16px;
    cursor: pointer;
}

.draft-btn {
    background: #C2DDF9;
    border-radius: 44px;
    font-weight: 500;
    font-size: 14px;
    height: 32px;
    border: 1px solid #C2DDF9;
    cursor: pointer;
}

.view-btn {
    color: #fff;
    font-weight: bold;
    border-radius: 4px;
    background-color: transparent;
    border: 1px solid #fff;
    cursor: pointer;
}

.share-btn {
   font-weight: bold;
   background-color: #fff; 
   border-radius: 4px;
   border: 0;
   cursor: pointer;

}

.add-money-proceed-spinner {
    line-height: normal !important;
}