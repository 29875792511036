@import '../main.scss';

.promotion-new {
    width: 566px;
    padding-bottom: 64px;
}

.promotion-card {
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    border-top: 12px solid $adminColor;
}

.promotion-header {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: $adminColor;
}

.promotion-date-picker {
    width: 100%;
    flex-direction: row;
    padding: 8px 0;
}

.promotion-date-icon {
    margin-right: 30px;
    color: #000;
}

.promotion-discount-text {
    color: #2b2d33;
    font-size: 14px;
    font-weight: 500;
}

.promotion-discount-btn {
    background-color: #fff;
    font-weight: 400;
    font-size: 14px;
    color: rgba(43, 45, 50, 0.8);
    border: 0px;
    cursor: pointer;

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
}

.promotion-value-div {
    padding: 12px 16px;
    border: 1px solid #D4D5D8;
}

.promotion-tab-div {
    border-bottom: 1px solid #D4D5D8;
}

.promotion-selected-tab {
    border-bottom: 2px solid $adminColor;
}

.upload-div {
    border: 1px solid #E6EAF0;
    border-radius: 4px;
}

.file-name {
    margin: 9px 0px 0px 10px;
    width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.image-size {
    width: 40px;
    height: 40px;
}

.voucher-new {
    width: 760px;
    border-bottom: 64px;
}

.voucher-card {
    width: 95%;
    background: #FFFFFF;
    box-shadow: 0px 8px 24px rgba(0, 35, 11, 0.12);
    border-radius: 12px;
}

.voucher-amount-div {
    // box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.16);
    border-radius: 12px 12px 0px 0px;
    padding: 36px 16px;
}

.voucher-amount-text {
    color: #fff;
    font-size: 32px;
    font-weight: 700;
}

.voucher-with-wrap {
    width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.slide-in {
    overflow: hidden;
    transition: all .5s ease-in-out;
    height: 0
}

.slide-in:target{
    height: 100%;
}