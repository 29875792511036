.fees {
  color: #000;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 1em;
}

.fee-input {
  padding: 0.5em;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
}
