@import '../main.scss';


.wallet-balance-card {
    width: 100%;
    padding: 16px;
    background: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}

.wallet-transferable-div {
    padding: 12px 0px;
}

.m-multiwallet-div {
    background-color: #f4f6f9;
    border-radius: 8px;
    padding: 8px;
    height: 175px;

    .type {
        font-size: 0.7rem;
        color: rgba(43, 45, 50, 0.8); 
    }

    .value {
        font-size: 14px;
        font-weight: bold;
    }

}

.multiwallet-details-card {
    padding: 23px;
    border: 1px solid #E6EAF0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    background-color: #fff;
}

.multiwallet-payment-method {
    border-radius: 12px !important;
    border: 1px solid #E6EAF0;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin-top: 12px;
}
