@import '../main.scss';
.primarySideBar {
    background: #fff !important;
    padding: 32px 20px;
    overflow: auto;
    height: 100vh;
    position: fixed !important;
    left: 0;
    z-index: 100;
    margin-top: 56px;
    scrollbar-width: thin;
    scrollbar-color: #6969dd #e0e0e0;

}

.primarySideBar::-webkit-scrollbar {
    // display: none;
    width: 5px;
}

.primarySideBar::-webkit-scrollbar-thumb {
    // display: none;
    border-radius: 50px;
    height: 8px !important;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}



.testModeBg {
    background-color: #454545 !important;
}

.status {
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    margin-top: -16px;
}

.iconRight {
    color: $mainTextColor;
    font-size: 16px;
    margin-top: 10px;
}

.nameDivCorp {
    padding-top: 1.5vh;
    margin-left: 8px;
}

.nameDivSide {
    padding-top: 25px;
    margin-left: 10px;
}

.nameDiv {
    padding-top: 2.2vh;
    margin-left: 8px;
}

.completeText {
    font-weight: 600; 
    font-size: 16px; 
    line-height: 24px; 
    color: $mainTextColor;
    width: 90%;
    text-decoration: underline;
}

.buxTeam {
    margin-left: 5px; 
    font-size: 18px; 
    color: $mainTextColor;
    font-weight: 700;
}

.menuDivStyle {
    font-style: normal;
    font-weight: 500;
    height: 40px;
    margin-bottom: 8px;
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;

}

.menuTextStyle {
    line-height: 20px;
    color: $mainTextColor !important;
}

.menuTestStyle {
    color: #fff !important;
    line-height: 20px;

}

.testModeBg {
    background-color: #454545;
}

.menuIcon { 
    margin-right: 10px; 
    opacity: 2; 
    border-radius: 20%;
    z-index: 1;
}

.activeMenuTextStyle {
    line-height: 20px;
    color: #fff;
}

.urlState {
    width: 100%;
    height: 40px;
    padding: 8px;
    border-radius: 10px;
}

.collapseState {
    width: 50%;
    height: 40px;
    padding: 8px;
    border-radius: 10px;
}

.sub-menu-collapse-state {
    width: 50%;
    border-radius: 10px;
    
}

.sub-menu-url-state {
    width: 100%;
    border-radius: 10px;
}

.sub-menu-url-state::after {
    border: 0 !important
}

.subMenuStyle {
    height: 100%; 
    border-right: 0; 
    margin-top: 0px;
}

.testModeText {
    color: $mainTextColor;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
}

.testText {
    color: #fff;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
}

.questionIcon {
    font-size: 16px;
    margin-right: 12px;
}

.switchMode {
    background-color: #D1D5DD;
}

.needHelpDiv {
    display: flex; 
    justify-content: center; 
    float: top;
    margin: 20px 0px 50px 0px;
    text-align: center; 
    padding: 8px; 
    border-radius: 20px; 
    display: flex; 
    width: 100%;
}

.test {
    
    background-color: #fff; 
}

.needHelpText {
    font-weight: bold; 
    font-size: 12px; 
}

.admin-bg {
    background-color: $adminColor !important;
    color: #fff !important;
}



