.cashbux-history-table .ant-table-body {
    overflow-x: auto;
}

.cashbux-history-table .table-content {
    color: #2B2D33;
    overflow: hidden;
    font-size: 12px;
    max-width: 146px;
    font-style: normal;
    font-weight: normal;
    line-height: 24px;
    text-overflow: ellipsis;
    font-weight: 700;
}

.suffix-icon {
    margin-top: -12px;
    margin-right: 12px;
    color: #000;
}

.date-pic {
    font-size: 14px;
    color: #2F3542;
    width: 178px;
}

.search-input {
    width: 180px; 
    height: 32px;
    border-radius: 4px; 
}

.cashbux-settlement-tab-btn {
    padding: 12px 18px 13px 18px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    height: 40px;
    color: #2b2d33;
    background-color: #ffffff;
}

.cashbux-settlement-tab-btn:hover {
    color: #40a9ff;
}

.cashbux-settlement-tab-btn.active {
    color: #ffffff;
    background-color: #40a9ff;
    border-color: #40a9ff;
}

.settlement-header {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
    margin-bottom: 20px;
}

.settlement-header-buttons {
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.settlement-totals {
    margin: 16px 0px 8px 0px;
    display: flex;
    border-top: 1px solid #D4D5D8;
    justify-content: flex-start;
    gap: 2em;
}

.settlement-totals div {
    padding: 12px 0px 12px 0px;
    text-align: center;
    color: #000;
}

.settlement-totals .value-label {
    color: #0D3D76;
    font-weight: bold;
}

.settlement-totals span {
    line-height: 24px;
}

.settlement-card .wc-header{
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
}

.collection-type-select {
    width: 128px;
    color: #000;
}

.settlement-filter {
    display: flex;
    justify-content: flex-start;
    gap: 1em;
    height: 32px;
}

.date-pic-hyphen {
    display: flex;
    align-items: center;
}

@media (max-width: 960px) {
    .settlement-header-buttons {
        flex-direction: column;
        margin-bottom: 0px;
    }

    .settlement-totals {
        flex-direction: column;
        gap: 0em;
    }

    .settlement-totals div { 
        display: flex;
        padding: 8px 0px 8px 0px;
        gap: 0.5em;
    }

    .collection-type-select {
        width: 100% !important;
    }

    .date-pic {
        width: 100% !important;
    }

    .search-input {
        width: 100% !important;
    }

    .settlement-filter {
        flex-direction: column;
        height: auto;
    }

    .date-pic-hyphen {
        display: none;
    }
}