.email {
  width: 100%;
}

.paymentMethod {
  width: 100%;
}

@media (min-width: 1280px) {
  .email {
    width: 95%;
  }

  .paymentMethod {
    width: 95%;
  }
}