@import '../main.scss';


.main-div {
    width: 645px;

    @media (max-width: 600px) {
        width: 375px;
    }
}

.annoucement-div {
    background-color: #fff;
    width: 645px;
    padding: 64px 0px;
    position: relative;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 4px;

    @media (max-width: 600px) {
        width: 375px;
        padding: 40px 0px;
    }
}

.announce-icon-div {
    padding: 10px;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 50px;
    width: 60px;
    position: absolute;
    top: -30px;
    left: 45%;
}

.announcement-text-div {
    padding: 0px 40px;

    @media (max-width: 600px) {
        padding: 0px 16px;
    }
}