@import '../main.scss';


.button-tab-width {
    width: 900px;
    text-align: left;

    @media (max-width: 600px) {
        width: 100%;
    }
}

.pricing-table-div {
    background: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    // padding: 40px;
    width: 900px;
    text-align: left;

    @media (max-width: 600px) {
        width: 100%;
        padding: 20px;
    }
}

.pricing-header {
    padding: 32px 40px;
    border-bottom: 1px solid #D4D5D8;
}

.pricing-data {
    padding: 20px;
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
}

.pricing-icon-size {
    max-width: 60%;
}