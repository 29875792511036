@import '../main.scss';

.unselected-tab {
    color: $mainTextColor;
    border-bottom: 2px solid #909196;
}

.terms-card {
    width: 726px;
    background-color: #fff;
    // padding: 32px 40px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 4px;

    @media (max-width: 600px) {
        width: 95%;
    }
}

.mobile-main-terms {
    @media (max-width: 600px) {
        padding-bottom: 20px;
        padding-left: 5%
    }
}

.agreed-padding {
    padding: 32px 40px;

    @media (max-width: 600px) {
        padding: 20px 16px;
    }
}

.not-agreed-padding {
    padding: 0px 32px 32px 32px;

    @media (max-width: 600px) {
        padding: 16px;
    }
}


.terms-agree {
    overflow-y: scroll;
    text-align: justify;
    scroll-behavior: smooth;
    // overflowY: 'scroll', 
    // marginTop: '37px', 
    // textAlign: 'justify'
}

.agreed-height {
    height: 100%;
}

.not-agreed-height {
    height: 450px;
    // padding: 0px 32px;

}

.terms-footer {
    // display: none;
    border-top: 4px solid #eeeeee;
    background-color: #fff;
    @media (max-width: 600px) {
        width: 95%;
    }
}

.terms-footer-btn-padding {
    padding: 32px 24px;
}

.mobile-terms {
    width: 95%;
}