.base {
  font-size: 16px;
  display: flex;
  gap: 1em;
  flex-direction: column;
  width: 71vw;
}

.card {
  width: 100%;
  padding: 2em;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.payout-card-1 {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.payout-text {
  font-weight: normal;
  font-size: 14px;
  color: rgb(144, 145, 150);
}

.fee-container {
  display: flex;
  gap: 2em;
}

.rail-container {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.rail-text {
  color: #000;
  font-weight: bold;
}

.button {
  align-self: flex-end;
}
