@import '../main.scss';

.leftDashboard {
    flex: 5;
    padding: 20px; 
}

.accountCard {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    background: #fff;
    // width: 54vw;
    height: 102px;
    color: $mainTextColor;
}

.pendingBorderTop {
    border-left: 7px solid $buxMainColor;
}

.rejectedBorderTop {
    border-left: 7px solid #ee2545;
}

.approvedBorderTop {
    border-left: 7px solid $doneColor;
}

.pendingBorder {
    border: 1px solid$buxMainColor;
    border-left: 4px solid $buxMainColor;
    background-color: $buxMainOpaques;
}

.rejectedBorder {
    border: 1px solid #ee2545;
    border-left: 4px solid #ee2545;
    background-color: rgba(226, 76, 76, 0.1);
}

.approvedBorder {
    border: 1px solid $doneColor;
    border-left: 1px solid #ee2545;
    background-color: #62CE90;
}

.completeIcon {
    height: 60px;
    width: 60px;
    border-radius: 50%; 
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    padding: 16px;
}


.completeCardText {
    font-size: 12px;
    font-weight: 800;
    text-align: center;
    padding: 0;
}

.border-complete {
    border: 2px solid #62CE90;
}

.border-pending {
    border: 2px solid $buxMainColor;
}

.text-pending {
    color: $buxMainColor;
}

.text-complete {
    color: #62CE90;
}

.progress-div {
    margin-top: 35%;
    margin-left: 50%;
}

.progress-text-div {
    margin-left: 5%;
}

.statusBar {
    margin-left: 8px;
        // paddingBottom: 8px,
    height: 30px;
    width: 120px;
    border-radius: 4px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardTracker {
    display: flex;
    width: 40vw;
    position: relative; 
    justify-content: space-around; 
    padding: 20px 0px 0px 0px
}

.card {
    display: flex; 
    width: 40vw;
}

.chart {
    padding: 10px;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
}

.no-multi-chart-width {
    width: 52vw !important;
}

.sales {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: $mainTextColor;
    margin-bottom: 20px;
    text-align: left;
}

.top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-center {
    display: flex;
    align-items: center;
}

.box-stats {
    display: flex;
    flex-direction: column;
    align-items: center; 
    background-color: #F4F6F9; 
    border-radius: 9px;
    width: 30%; 
    margin: 0 5px; 
    padding: 8px 10px;
}

.tracker-card {
    height: 100%;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    padding: 8px;
}

.test-mode-pill {
    background: #454545;
    border-radius: 40px;
    padding: 2px 8px;
    font-size: 10px;
    width: 76px; 
    color: #FFFFFF;
    text-align: center;
    margin-bottom: 12px;
}

.account-pill {
    border-radius: 40px;
    padding: 2px 8px; 
    font-size: 10px;
    width: 64px;
    color: #fff;
    text-align: center;
}

.footer-disable {
    font-size: 12px;
    margin: 0;
    padding: 0px 4px;
}

.disable-color {
    color: #97979c;
}

.disable-bg {
    background-color: #97979c;
}

.icon-space {
    margin-right: 4px;
    margin-top: -2px;
}

.test-bg {
    background-color: #454545;
    color: #FFF;
    font-size: 14px;
    font-weight: 900;
    padding: 4px;
    text-align: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.bux-wallet-card {
    width: 100%;
    background-color: #fff;
    justify-content: center;
    background-repeat:no-repeat;
    -webkit-background-size:cover;
    -moz-background-size:cover;
    -o-background-size:cover;
    background-size:cover;
    background-position:center;
    height: 100%;
    padding-bottom: 64px;
}

.caret-img { 
    padding: 10px; 
    width: 32px;
    height: 32px;
}

.wallet-wave-img {
    width: 100%;
    position: absolute;
    bottom: 0; 
    z-index: 0;

    @media (max-width: 600px) {
        bottom: 10px;
    }
}

.wallet-balance-div {
    display: flex;
    border-top: 1px solid #eeeeee; 
    padding: 16px 0;
}

.balance-div {
    background-color: #FFF;
    padding: 16px;
}

.mobile-div {
    margin-top: 16px;
}

.transaction-div {
    height: 100%;
    border-radius: 4px;
    margin-top: 12px;
    background: #fff;
    padding: 12px 8px;

    .transaction-text {
        font-size: 10px;
        color: $mainTextColor;
        line-height: 16px;
        font-weight: 600;
    }
}

.multi-wallet-card {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}

.date-icon {
    margin-top: -12px;
    margin-right: 12px;
    color: #000;
}



.m-transaction-div {
    background-color: #f4f6f9;
    border-radius: 8px;
    padding: 8px;
    height: 100%;

    .type {
        font-size: 0.7rem;
        color: rgba(43, 45, 50, 0.8); 
    }

    .value {
        font-size: 14px;
        font-weight: bold;
    }

}

.divider {
    height: 36px;
    background-color: #d4d5d8;
}

.sentro-card {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}