@import '../main.scss';


.alert-message {
    background-color: rgba(226, 76, 76, 0.1);
    border-left: 4px solid #E24C4C;
    width: 100%;
    border-radius: 4px;
    margin-bottom: 20px;
    margin-top: 20px;
    text-align: left;
    padding: 10px;
}

.footer-note {
    padding: 16px 32px;
    color: $mainTextColor;
    font-weight: 600;
}


.progress-div {
    position: relative;
    height: 40px; 
    display: flex;
    justify-content: space-between;
}

.note-div {
    background: rgba(119, 178, 242, 0.16);
    border: 1px solid #77B2F2;
    box-sizing: border-box;
    border-radius: 2px; 
    padding: 8px 16px;
}

.info-icon {
    color: #77B2F2 !important;
}

.select-input {
    height: 32px !important; 
    font-size: 14px !important;
}

.text-input {
    width: 98% !important;
    height: 32px !important;
}

.kyb-title-div {
    padding-bottom: 8px;
    border-bottom: 1px solid #000000;
}

.kyb-margin-style {
    margin: 30px 0px 10px 0px;
}

.kyb-file-div {
    border: 1px solid #eeeeee;
    // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
}

.kyb-reviewing-div {
    border-bottom:  1px solid #D1D5DD; 
    padding-bottom: 16;
}

.divider {
    border-top: 1px solid #eeeeee;
}

.profile-initials {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    line-height: 32px;
    background-color: blue;
}