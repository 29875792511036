@import '../main.scss';


.portal-div-card {
    padding: 32px 16px 0px 30px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    background: #fff;
    width: 645px;
    text-align: left;

    @media (max-width: 600px) {
        width: 100%;
        padding: 16px;
    }
}

.credentials-div-card {
    background: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 32px;
    width: 645px;
    text-align: left;

    @media (max-width: 600px) {
        width: 100%;
        padding: 16px;
    }
}

.creds-note-div {
    width: 645px;
    text-align: left;

    @media (max-width: 600px) {
        width: 100%;
    }

}