@import '../main.scss';

.primaryHeader {
    background: #fff !important;
    box-shadow: 0px 3px 5px rgba(57,63,72,0.1);
    position: fixed;
    padding: 0px 20px !important;
    width: 100%;
    z-index: 120;
}

.mobilePrimaryHeader {
    padding: 0 !important;
    height: 60px !important;
    top: 0;
    background: #fff !important;
    z-index: 120;

}

.mobilePrimaryHeader {
    padding: 0 !important;
    height: 60px !important;
    top: 0;
    background: #fff !important;
    z-index: 120;

}

.mobileFooterMenu {
    background-color: #fff;
    width: 100%;
    height: 75px;
    padding: 0 8px;
    position: fixed;
    z-index: 101;
    bottom: 0;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.16);
    line-height: 56px;

    .icon-style {
        padding: 4px;
        border-radius: 8px;
    }

    .link-style {
        margin-top: -2px;
        font-weight: 500;
        font-size: 12px;
        line-height: 0px;
    }
}

.headerContent {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.space-around {
    display: flex;
    justify-content: space-around;
}


.titleDiv {
    width: 370;
    padding: 30px 20px 20px 0px;

    .closeIconDiv {
        position: absolute; 
        right: 0;
        margin: -25px -5px 0px 0px;
    }

    .titleText {
        font-family: Inter; 
        width: 370px;
        line-height: 24px; 
        font-size: 16px; 
        padding: 10px; 
        text-align: center;
        color: $mainTextColor; 
        opacity: '0.8'
    }

    .btnPosition {
        width: 100%; 
        text-align: right; 
        margin-top: 15px;
    }

    .badgeIcon {
        font-size: 10; 
        margin: 2px 5px; 
        background-color: #E24C4C;
    }
}