.ant-carousel .slick-slide {
    overflow: hidden;
  }
  
.ant-carousel .slick-slide h3 {
    color: #fff;
}

.table-row-light {
  background-color: #ffffff;
}
.table-row-dark {
  background-color: #fbfbfb;
}

.ant-table-thead tr th{
  background-color: #D1D5DD !important;
}


.ant-table-thead > tr >th .sub-column{
  background: #eeeeee !important;
}

.ant-radio-button-wrapper {
  position: static !important;
  display: inline-block;
  height: 32px;
  margin: 0;
  padding: 0 15px;
  color: rgba(0, 0, 0, 0.65);
  line-height: 30px;
  background: #fff;
  border-left: 1px solid #d9d9d9 !important;
  border-top-width: 1.02px;
  cursor: pointer;
  -webkit-transition: color 0.3s, background 0.3s, border-color 0.3s;
  transition: color 0.3s, background 0.3s, border-color 0.3s;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked {
  border: 1px solid #0D3D76 !important;
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #A9A9A9 !important;
  border-color: #d9d9d9 !important;
}
  
.ant-slider-track {
  background: #ff911b !important;
}

.ant-slider-handle {
  height: 24 !important;
  box-shadow: 0px 4px 6px rgba(130, 136, 148, 0.32), 0px 0px 0px rgba(130, 136, 148, 0.16) !important;
  border: solid 2px #fff !important;
}
.avatar-uploader > .ant-upload {
  width: 100% !important;
}

.ant-drawer-body {
  padding: 0px !important;
}

.ant-upload {
  width: 100%;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #F5922F !important;
}

.ant-radio-inner::after {
  background-color: #F5922F !important;

}

.ant-tabs-ink-bar {
  background-color: #0D3D76 !important;
}

.ant-tabs-tab-active {
  opacity: 1 !important;
}

.ant-tabs-tab {
  opacity: 0.5;
}


.ant-carousel .slick-slide {
  text-align: center;
  height: 100% !important;
  overflow: hidden;
  padding-bottom: 20px;
}

.ant-carousel .slick-dots-bottom {
  bottom: 0;
}

.ant-carousel .slick-dots li button {
  display: block;
  width: 12px !important;
  height: 12px !important;
  padding: 0;
  font-size: 0;
  color: gray !important;
  background-color: gray !important;
  border: 0;
  border-radius: 100px !important;
  outline: none;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  border-radius: 100px;

}

.ant-carousel .slick-dots li.slick-active button {
  width: 24px !important;
  height: 12px;
  border-radius: 100px;
  background: #F5922F !important;
  opacity: 1;
}

.ant-collapse-header{
  height: 72px;
}

.ant-popover{
  z-index: 1 !important;
}

.ant-checkbox-checked .ant-checkbox-inner{
  background-color: #F5922F !important;
  border: 1px solid #F5922F !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after{
  background-color: #F5922F !important;
  border: 1px solid #F5922F !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #F5922F !important;
}

.copy .ant-input-group-addon{
  background-color: #F5922F !important;
}

.cb_payment_methods .ant-checkbox-group-item {
  width: 100% !important;
}


.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title{
  margin-left: -18px;
}

.ant-menu-submenu-title{
  padding-left: 8px !important;
}

.voucher-drawer .ant-drawer-content-wrapper{
  border-radius: 30px 0px 0px 20px !important;
}

.voucher-drawer .ant-drawer-content {
  border-radius: 30px 0px 0px 20px !important;
  
}

.my-checkout-channel-disabled-tooltip.ant-tooltip {
  max-width: 320px;
}

.my-checkout-channel-disabled-tooltip .ant-tooltip-inner {
  background: rgba(252, 247, 230, 0.9);
  color: rgba(43, 45, 50, 0.8);
  border: 1px solid #F4B24A;
  border-radius: 2px;
  padding: 8px 16px;
}

.my-checkout-channel-disabled-tooltip .ant-tooltip-arrow::before {
  background: #F4B24A;
}