@function set-text-color($color) {
    @if(lightness($color) > 50) {
        @return $mainTextColor;
    }

    @else {
        @return #fff;
    }
}

@each $color, $value in $theme-colors {
    .bg-#{$color} {
        background-color: $value !important;
        color: #fff;
    }

    .text-#{$color} {
        color: $value !important;

        // &:hover {
        //     color: #fff !important;
        // }
    }

    .border-top-#{$color} {
        border-top: 4px solid $value;

    }

    .border-#{$color} {
        border: 1px solid $value;
    }

    .status-#{$color} {
        border: 1px solid $value;
        border-left: 4px solid $value;
    }

    .border-status-#{$color} {
        border: 2px solid $value;
    }

    .border-bottom-#{$color} {
        border-bottom: 2px solid $value;
    }

    .scroll-thumb-#{$color}::-webkit-scrollbar-thumb {
        background-color: $value
    }

    .voucher-hover-#{$color} {
        &:hover {
            background-color: $value !important;
        }
    }
}

@each $color, $value in $o-theme-colors {
    .bg-opaque-#{$color} {
        background-color: $value !important;
    }
}

@each $color, $value in $gradient-colors {
    .bg-#{$color} {
        background: $value;
    }
}