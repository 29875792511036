@import '../main.scss';


.terms-modal {
    padding: 32px;
}

.terms-modal-header {
    font-size: 1.125rem;
    line-height: 28px;
    font-weight: bold;
}

.terms-sub-text {
    font-size: 0.875rem;
    line-height: 24px;
    font-weight: 400;
}