@import '../main.scss';

.rebate-div {
    width: 71vw;
    padding: 32px;
    background: #fff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
}

.rebate-switch-div {
    margin: 12px 20px 12px 12px;
}

.rebate-column-color {
    background-color: #eeeeee !important;
}

.rebate-error-div {
    background: rgba(218, 86, 54, 0.16);
    border: 1px solid #E55D3A;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 16px;
}