@import '../main.scss';


.sub-div {
    border: 1px solid #E6EAF0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    border-radius: 0px 0px 4px 4px;
    width: 30vw;
    padding: 32px;

    @media (max-width: 600px) {
        width: 100%;
    }

    @media(min-width: 720px) and (max-width: 820px) {
        width: 50%
    }

    // @media(min-width: 1330px) {
    //     width: 33vw;
    // }

    @media (min-width: 1331px) and(max-width: 1440px)  {
        width: 32vw;
    }

    @media(min-width: 1441px) and (max-width: 1640px) {
        width: 34vw;
    }
}

.two-col-input {
    width: 13vw !important;

    @media (min-width: 320px) and (max-width: 820px) {
        width: 100% !important;
    }
}

.input-100 {
    width: 100%;
}

.input-box-div {
    padding: 12px 16px;
    border: 1px solid #EEEEEE;
    box-sizing: border-box;
}

.charged-div {
    width: 50vw;

    @media (min-width: 1331px) and(max-width: 1360px)  {
        width: 90%;
    }

    @media (min-width: 1440px) and(max-width: 1440px)  {
        width: 80%;
    }
}


.disclaimer-div {
    background: rgba(244, 193, 10, 0.16);
    border: 1px solid #F4B24A;
    box-sizing: border-box;
    border-radius: 2px;
}

.qr-main-screen {
    background: #e5e5e5;
    padding: 32px 0px;
    width: 100%;
    // text-align: center;
    @media (min-width: 320px) and (max-width: 820px) {
        padding: 0px;
        background: #fff;
    }
}

.qr-details-div {
    width: 680px;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    

    @media (min-width: 320px) and (max-width: 820px) {
        width: 100% !important;
        border-radius: 0px;
    }
}

.qr-header-div {
    background: linear-gradient(102.61deg, #004888 0%, #00BBFF 100%);
    border-radius: 20px 20px 0px 0px;
    padding: 16px 0px;
    @media (min-width: 320px) and (max-width: 820px) {
        border-radius: 0px;
    }
}

.qr-info {
    padding: 0px 64px;

    @media (min-width: 320px) and (max-width: 820px) {
        padding: 0px 16px;
    }

}

.qr-profile-pic {
    width: 32px;
    height: 32px;
    border-radius: 50px;
}

.qr-md-profile-pic {
    width: 64px;
    height: 64px;
    border-radius: 50px;
}

.qr-business-name {
    max-width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.bottom-line {
    border-bottom: 1px solid #D4D5D8;

    margin: 0px 64px;

    @media (min-width: 320px) and (max-width: 820px) {
        margin: 0px 32px;
    }
}

.qr-text-amount {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.qr-header-details {
    font-weight: 700;
    font-size: 16px;
    color: #2B2D33;
    padding-bottom: 8px;
    border-bottom: 1px solid #D4D5D8;
}

.qr-placeholder {
    font-weight: 400;
    margin-top: 12px;
    font-size: 14px;
    line-height: 20px;
    color: rgba(43, 45, 50, 0.8)
}

.qr-surcharge-div {
    background: rgba(252, 247, 230, 0.9);
    color: rgba(43, 45, 50, 0.8);
    border: 1px solid #F4B24A;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 8px 16px;
}

.qr-dashed-line {
    border-bottom: 1px dashed #D4D5D8;
}

.qr-footer {
    border-top: 1px solid #D4D5D8;
    border-bottom: 1px solid #D4D5D8;
    padding: 32px 64px;

    @media (min-width: 320px) and (max-width: 820px) {
        padding: 16px 0px;
    }
}
