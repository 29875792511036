@import '../main.scss';

@mixin grp-btn-main() {
    background-color: #FFF;
    border: 1px solid $buxMainColor;
    height: 40px;
    border-radius: 4px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    padding: 7px 16px;
    color: $buxMainColor;

    &:hover {
        cursor: pointer;
        background-color: $buxSubColor;
        border: 1px solid $buxSubColor;
    }
}

@mixin grp-btn($theme: 'main') {
    @include grp-btn-main;
    @if $theme == 'me' {
        color: $buxMeColor;
        border: 1px solid $buxMeColor;

        &:hover {
            color: #fff;
            background-color: $buxMeColor;
            border: 1px solid $buxMeColor;
        }
    }

    @else if $theme == 'gig' {
        color: $buxGigColor;
        border: 1px solid $buxGigColor;

        &:hover {
            color: #fff;
            background-color: $buxGigColor;
            border: 1px solid $buxGigColor;
        }
    }

    @else if $theme == 'sme' {
        color: $buxSmeColor;
        border: 1px solid $buxSmeColor;

        &:hover {
            color: #fff;
            background-color: $buxSmeColor;
            border: 1px solid $buxSmeColor;
        }
    }

    @else if $theme == 'biz' {
        color: $buxBizColor;
        border: 1px solid $buxBizColor;

        &:hover {
            color: #fff;
            background-color: $buxBizColor;
            border: 1px solid $buxBizColor;
        }
    }

    @else if $theme == 'main' {
        color: $buxMainColor;
        border: 1px solid $buxMainColor;

        &:hover {
            color: #fff;
            background-color: $buxMainColor;
            border: 1px solid $buxMainColor;
        }
    }
}

.grp-btn--main {
    @include grp-btn("main");
}

.grp-btn--me {
    @include grp-btn('me');
}

.grp-btn--gig {
    @include grp-btn('gig');
}

.grp-btn--sme {
    @include grp-btn('sme');
}

.grp-btn--biz {
    @include grp-btn('biz');
}


@mixin link-text-main() {
    color: $buxMainColor;
    text-decoration: 'none';
    &:hover {
        color: $buxMainColor;
    }
}

@mixin link-text($theme: 'main') {
    @include link-text-main;
    @if $theme == 'me' {
        color: $buxMeColor;
        text-decoration: 'none';
        &:hover {
            color: $buxMeColor;
        }
    }
    @else if $theme == 'gig' {
        color: $buxGigColor;
        text-decoration: 'none';
        &:hover {
            color: $buxGigColor;
        }
    }
    @else if $theme == 'sme' {
        color: $buxSmeColor;
        text-decoration: 'none';
        &:hover {
            color: $buxSmeColor;
        }
    }
    @else if $theme == 'biz' {
        color: $buxBizColor;
        text-decoration: 'none';
        &:hover {
            color: $buxBizColor;
        }
    }
    @else if $theme == 'main' {
        color: $buxMainColor;
        text-decoration: 'none';
        &:hover {
            color: $buxMainColor;
        }
    }

}

.link-text--main {
    @include link-text("main");
}

.link-text--me {
    @include link-text('me');
}

.link-text--gig {
    @include link-text('gig');
}

.link-text--sme {
    @include link-text('sme');
}

.link-text--biz {
    @include link-text('biz');
}

@mixin switch-btn-main() {
    background: $buxMainColor !important;
    &:hover {
        background: $buxMainColor;
    }
}

@mixin switch-btn($theme: 'main') {
    @include switch-btn-main;
    @if $theme == 'me' {
        background: $buxMeColor !important;
        &:hover {
            background: $buxMeColor;
        }
    }
    @else if $theme == 'gig' {
        background: $buxGigColor !important;
        &:hover {
            background: $buxGigColor;
        }
    }
    @else if $theme == 'sme' {
        background: $buxSmeColor !important;
        &:hover {
            background: $buxSmeColor;
        }
    }
    @else if $theme == 'biz' {
        background: $buxBizColor !important;
        &:hover {
            background: $buxBizColor;
        }
    }
    @else if $theme == 'main' {
        background: $buxMainColor !important;
        &:hover {
            background: $buxMainColor;
        }
    }

}

.switch-btn--main {
    @include switch-btn("main");
}

.switch-btn--me {
    @include switch-btn('me');
}

.switch-btn--gig {
    @include switch-btn('gig');
}

.switch-btn--sme {
    @include switch-btn('sme');
}

.switch-btn--biz {
    @include switch-btn('biz');
}


@mixin radio-btn-main() {
    color: $buxMainColor !important;
    &:checked {
        background-color: $buxMainColor;
    }
}

@mixin radio-btn($theme: 'main') {
    @include radio-btn-main;
    @if $theme == 'me' {
        background: $buxMeColor !important;

        &:hover {
            background-color: $buxMainColor;
            border: 1px solid $buxMainColor;
        }
        &:checked {
            background-color: $buxMeColor !important;
        }
    }
    @else if $theme == 'gig' {
        background: $buxGigColor !important;
        &:checked {
            background-color: $buxGigColor !important;
        }
    }
    @else if $theme == 'sme' {
        background: transparent !important;
        width: 20px;

        &:checked {
            background: $buxSmeColor !important;
        }
        // &:hover {
        //     background-color: $buxSmeColor !important ;
        // }
    }
    @else if $theme == 'biz' {
        background: $buxBizColor !important;
        &:checked {
            background-color: $buxBizColor !important;
        }
    }
    @else if $theme == 'main' {
        background: $buxMainColor !important;
        &:checked {
            background-color: $buxMainColor !important;
        }
    }

}

.radio-btn--main {
    @include radio-btn("main");
}

.radio-btn--me {
    @include radio-btn('me');
}

.radio-btn--gig {
    @include radio-btn('gig');
}

.radio-btn--sme {
    @include radio-btn('sme');
}

.radio-btn--biz {
    @include radio-btn('biz');
}


