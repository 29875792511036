.field-row-container {
    display: flex;
    justify-content: space-between;
    gap: 8px;    
    width: 100%;
}

.field-row-container .full-width-grid-item {
    width: 100%;
}

.field-row-container .MuiGrid-item:not(.full-width-grid-item) {
    width: 50%;
}

.field-row-container .MuiTextField-root {
    width: 100%;
}

.field-row-container .error-field-message {
    color: #c0492c;
    margin: 0px;
    font-size: 12px;
    margin-top: -6px;
}

.field-row-container .MuiTextField-root label[data-shrink="false"] {
    width: 90%;
}

@media (max-width: 600px) {
    .field-row-container {
        flex-direction: column;
    }

    .field-row-container .MuiGrid-item:not(.full-width-grid-item) {
        width: 100% !important;
    }

    .fee-config-card .ant-card-body div div:nth-child(2) {
        flex-direction: column;
    }

    .fee-config-card .ant-card-body div div:nth-child(2) input {
        width: 100% !important;
    }
}