.field-row-container {
    display: flex;
    justify-content: space-between;
    gap: 8px;    
    width: 100%;
}

.field-row-container .full-width-grid-item {
    width: 100%;
}

.field-row-container .MuiGrid-item:not(.full-width-grid-item) {
    width: 50%;
}

.field-row-container .MuiTextField-root {
    width: 100%;
}

.field-row-container .error-field-message {
    color: #c0492c;
    margin: 0px;
    font-size: 12px;
    margin-top: -6px;
}

.field-row-container .MuiTextField-root label[data-shrink="false"] {
    width: 90%;
}

.name-and-email p {
    margin: 0px;
}

.name-and-email p:first-child {
    font-weight: 700;
}

.primary-btn,
.primary-btn:hover,
.primary-btn:active,
.primary-btn:focus {
    background-color: #0A315E;
    border: 1px solid #0A315E;
}

.secondary-btn,
.secondary-btn:hover,
.secondary-btn:active,
.secondary-btn:focus {
    border: 1px solid #0A315E ;
    color: #0A315E ;
}

.button-container {
    display: flex;
    justify-content: space-between !important;
    gap: 1em;
}

.merchant-filter-container {
    padding: 20px 0px;
    display: flex;
    gap: 1em;
}

.merchant-filter-container .ant-input-affix-wrapper {
    width: 450px;
    height: 40px;
}

.merchant-filter-container .date-picker div input {
    height: 40px;
}

.dialog-box-modal .dialog-title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 300;
    color: #2b2d33;
}

.dialog-box-modal .dialog-desc {
    margin-top: 20px;
    font-size: 14px;
    line-height: 18px;
    color: #2b2d33;
}

.dialog-box-modal  .btn-div {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.dialog-box-modal .cancel-btn {
    width: 150px;
    height: 40px;
    background: #fff;
    border: 1px solid #f5922f;
    border-radius: 10px;
    color: #f5922f;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    outline: 0;
    cursor: pointer;
}

.dialog-box-modal .cancel-btn:hover {
    background: #f5922f;
    color: #fff;
}

.dialog-box-modal .cancel-btn:active {
    background: #DA7F24;
    opacity: 1;
}

.dialog-box-modal .confirm-btn {
    width: 150px;
    background: #f5922f;
    border: 1px solid #F5922F;
    color: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 16px;
    height: 40px;
    cursor: pointer;
}

.dialog-box-modal .confirm-btn:hover {
    opacity: 0.8;
}

@media (max-width: 768px) {
    .merchant-filter-container {
        flex-direction: column;
    }

    .merchant-filter-container .ant-input-affix-wrapper {
        width: 100%;
    }

    .merchant-filter-container button {
        width: 100%;
    }
}

@media (max-width: 600px) {
    .field-row-container {
        flex-direction: column;
    }

    .field-row-container .MuiGrid-item:not(.full-width-grid-item) {
        width: 100% !important;
    }

    .merchant-categories-card .ant-card-body div div:nth-child(2) {
        flex-direction: column;
        gap: 1em;
    }

    .merchant-categories-card .ant-card-body div div:nth-child(2) input {
        width: 100% !important;
    }

    .button-container,
    .button-container div {
        flex-direction: column;
    }
}
