.field-row-container {
    display: flex;
    justify-content: space-between;
    gap: 8px;    
    width: 100%;
}

.field-row-container .full-width-grid-item {
    width: 100%;
}

.field-row-container .MuiGrid-item:not(.full-width-grid-item) {
    width: 50%;
}

.field-row-container .MuiTextField-root {
    width: 100%;
}

.field-row-container .error-field-message {
    color: #c0492c;
    margin: 0px;
    font-size: 12px;
    margin-top: -6px;
}

.field-row-container .MuiTextField-root label[data-shrink="false"] {
    width: 90%;
}

.primary-btn,
.primary-btn:hover,
.primary-btn:active,
.primary-btn:focus {
    background-color: #0A315E;
    border: 1px solid #0A315E;
}

.secondary-btn,
.secondary-btn:hover,
.secondary-btn:active,
.secondary-btn:focus {
    border: 1px solid #0A315E ;
    color: #0A315E ;
}

.button-container {
    display: flex;
    justify-content: flex-end;
    gap: 1em;
}

.filter-container {
    padding: 20px 0px;
    display: flex;
}

.filter-container span:first-child {
    width: 50%;
}

.filter-container button {
    margin-left: 15px;
}

@media (max-width: 600px) {
    .field-row-container {
        flex-direction: column;
    }

    .field-row-container .MuiGrid-item:not(.full-width-grid-item) {
        width: 100% !important;
    }

    .segment-fees-card .ant-card-body div div:nth-child(2) {
        flex-direction: column;
        gap: 1em;
    }

    .segment-fees-card .ant-card-body div div:nth-child(2) input {
        width: 100% !important;
    }

    .button-container {
        flex-direction: column;
    }

    .filter-container button {
        margin-left: 0px;
        width: 100%;
    }

    .filter-container span:first-child {
        width: 100%;
    }
}