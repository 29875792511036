@import '../main.scss';

.table-card-div {
    margin-top: 24px;
    padding:  45px 32px;
    background-color: #fff;
}

.dropdown {
    width: 150px;
}

.search-bar {
    width: 200px !important;
    height: 35px;
    z-index: 1;
}

.total-user {
    border: 1px solid $mainTextColor;
    border-radius: 4px;
    box-sizing: border-box;
    background: #616675;
    color: #fff;
    padding: 8px;
    font-weight: normal;
}

.filter-div {
    padding: 20px 0px;
    display: flex;
}

.table-format {
    width: 15% !important;
    font-weight: bold;
}

.with-link {
    max-width: 100%; 
    overflow: hidden; 
    text-overflow: ellipsis;
    white-space: nowrap; 
    padding-right: 12px;
}

.menu-style {
    font-size: 14px !important;
    line-height: 24px !important;
    color: $mainTextColor !important;
    padding: 10px !important;
    margin: auto !important;
}

.new-summary {
    width: 50%; 
    border: 1px solid #D1D5DD;
    display: flex;
    justify-content: center; 
    padding: 5px 0px;
}