.sentro-image {
    align-items: flex-start;
}

@media (max-width: 768px) {
    .sentro-card {
        flex-direction: column !important;
    }

    .visit-store-link-container {
        justify-content: flex-start;
    }

    .visit-store-link-container > a {
        font-size: 17px !important;
    }

    .secondary-text {
        padding: 0 !important;
        font-size: 16px;
    }

    .sentro-shop {
        display: none;
    }
}

@media (max-width: 599.98px) {
    .sentro-div {
        flex-direction: column;
    }

    .sentro-image {
        justify-content: center;
    }

    .sentro-caption {
        text-align: center;
    }
}