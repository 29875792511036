.button {
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;

  display: flex;
  gap: 0.5em;
  align-items: center;

  border: none;
  padding: 0.6em 2em;
  border-radius: 10px;
  background-color: #f5922f;
  cursor: pointer;
}
