.transfer-feature-container {
  margin: 5em 2.5em 2.5em 0;
}

.transfer-feature-container-spacing {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.summary-cards-container {
  display: flex;
  gap: 2em;
}

.status-filter-and-export-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.base {
  padding: 64px 32px 0px 0px;
  width: 100%;
}

.transfer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wallet-balance-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.title {
  font-size: 28px;
  font-weight: bolder;
}

.card {
  width: 100%;
  padding: 2em;
  background-color: #fff;
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
}

.wallet-balance-title {
  color: rgb(43, 45, 50);
  opacity: 0.8;
  font-weight: 400;
  font-size: 12px;
  margin: 0;
}

.wallet-balance-amount {
  color: rgb(43, 45, 50);
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}
