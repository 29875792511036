
$buxMainColor: #F5922F;
$buxSubColor: #F8B966;
$buxMainOpaques: #FEF6EE;

$buxMeColor: #0DAED0;
$buxMeSubColor: #48DBFB;
$buxMeOpaques: #ECF9FB;


$buxGigColor: #41BD5C;
$buxGigSubColor: #B8E994;
$buxGigOpaques: #F0FAF2;

$buxSmeColor: #E3632A;
$buxSmeSubColor: #F3863C;
$buxSmeOpaques: #FDF3EE;


$buxBizColor: #0051B0;
$buxBizSubColor: #2679DC;
$buxBizOpaques: #EBF1F9;

$mainTextColor: #2B2D33;
$doneColor: #1DD28B;

$adminColor: #0A315E;
$adminSubColor: #254A76;
$adminSubColor: #254A76;

$theme-colors: (
    "nil": $adminColor,
    "me_": $buxMeColor,
    "gig": $buxGigColor,
    "sme": $buxSmeColor,
    "biz": $buxBizColor,
);

$s-theme-colors: (
    "main": $adminSubColor,
    "me_": $buxMeSubColor,
    "gig": $buxGigSubColor,
    "sme": $buxSmeSubColor,
    "biz": $buxBizSubColor,
);

$o-theme-colors: (
    "main": $buxMainOpaques,
    "me_": $buxMeOpaques,
    "gig": $buxGigOpaques,
    "sme": $buxSmeOpaques,
    "biz": $buxBizOpaques,
);

$gradient-colors:( 
    "green-gradient": linear-gradient(286.94deg, #2B91A6 12.46%, #78C27E 83.49%),
    "orange-gradient": linear-gradient(286.94deg, #E66E1A 12.46%, #E2A000 83.49%),
    "blue-gradient": linear-gradient(286.94deg, #283FB6 12.46%, #1B73C6 83.49%),
    "indigo-gradient": linear-gradient(286.94deg, #573BDA 12.46%, #B35693 83.49%),
    "violet-gradient": linear-gradient(286.94deg, #42275A 12.46%, #734B6D 83.49%),
    "red-gradient": linear-gradient(286.94deg, #FF345D 12.46%, #FE5B3E 83.49%),
    "pink-gradient": linear-gradient(286.94deg, #B24592 12.46%, #F15F79 83.49%),
    "cyan-gradient": linear-gradient(286.94deg, #1E6BF0 12.46%, #1ACDFC 83.49%),
    "light-blue-gradient": linear-gradient(286.94deg, #3F2B96 12.46%, #A8C0FF 83.49%)
);

.nameStyle { 
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
}

.testColor {
    color: #fff !important;
}

.liveColor {
    color: $mainTextColor;
}

.adminColor {
    color: $adminColor !important;
}

.grey-color {
    color: #909196 !important;
}

.charcoal-color {
    color: #54575F
}

.normalSizeStyle {
    font-size: 0.9vw;
    line-height: 24px;
    font-weight: 500;

    .wrap {
        width: 8vw;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    @media (max-width: 1260px) {
        font-size: 14px
    }
}

.card-text-size {
    font-size: 0.8vw;
    font-weight: 500;
    @media (max-width: 600px) {
        font-size: 14px;
    }
}

.normalTextStyle {
    font-size: 14px;
    line-height: 20px;
}


.smallTextStyle {
    font-size: 12px;
    line-height: 16px;
}


.smallBoldStyle {
    font-size: 14px;
    line-height: 16px;
    font-weight: 700;
    margin-top: 2px;

    .smallSemiBoldStyle {
        font-weight: 500;
    }

    @media (max-width: 600px) {
        font-size: 1
    }
}

.userNameStyle {
    font-size: 12px; 
    font-weight: 700;
    overflow-wrap: anywhere;
}

.flex {
    display: flex;
}

.flex-start {
    display: flex;
    justify-content: flex-start;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
}

.flex-around {
    display: flex;
    justify-content: space-around;
}

.flex-basis-30 {
    display: flex;
    justify-content: flex-start;
    flex-basis: 30%;
}

.flex-nowrap {
    flex-wrap: nowrap;
}

.twoCol {
    display: flex;
    justify-content: space-between;
}

.flex-center {
    display: flex;
    justify-content: center;
}

.boldTextStyle {
    color: $mainTextColor;
    font-size: 0.9vw;
    font-weight: bold;
    line-height: 20px;

    .semiBoldText {
        font-weight: 300;
        font-size: 0.9vw;
        color: $mainTextColor;
    }

    @media (max-width: 1260px) {
        font-size: 16px;
    }
}

.completeTextColor {
    color: $doneColor;
}

.linkText {
    font-weight: 600;
    font-size: 17px;
}

.completeBgColor {
    background-color: $doneColor;
}

.wallet-text-color {
    color: #0d3d76;
    line-height: 20px;
}

.wc-header {
    font-size: 16px;
    font-weight: bold;
    color: $mainTextColor;
}

.wc-text {
    font-size: 14px;
}

.screen-title-text {
    color: $mainTextColor;
    font-size: 24px;
    line-height: 20px;
    font-weight: bold;
}

.rejected-color {
    color: #C0492C;
}

.pending-color {
    color: #E3632A;
}

.success-color {
    color: #62CE90;
}

.value-color {
    color: #0D3D76 !important;
}

.padding-8 {
    padding: 8px
}

.padding-16 {
    padding: 16px;
}

.padding-20 {
    padding: 20px;
}

.padding-32 {
    padding: 32px;
}

.padding-40 {
    padding: 32px;
}

.padding-lr-16 {
    padding: 0px 16px;
}

.padding-lr-20 {
    padding: 0px 20px;
}

.padding-lr-32 {
    padding: 0px 32px;
}

.padding-lr-40 {
    padding: 0px 40px;
}

.padding-top-4 {
    padding-top: 4px;
}

.padding-top-8 {
    padding-top: 8px;
}

.padding-top-16 {
    padding-top: 16px;
}

.padding-top-32 {
    padding-top: 32px;
}

.padding-top-40 {
    padding-top: 40px;
}

.padding-right-16 {
    padding-right: 16px;
}

.padding-right-32 {
    padding-right: 32px;
}

.padding-bot-16 {
    padding-bottom: 16px;
}

.padding-bot-20 {
    padding-bottom: 20px;
}

.padding-bot-22 {
    padding-bottom: 22px;
}

.padding-bot-24 {
    padding-bottom: 24px;
}

.padding-bot-32 {
    padding-bottom: 32px;
}

.padding-bot-40 {
    padding-bottom: 40px;
}

.padding-left-24 {
    padding-left: 24px;
}

.top-4 {
    margin-top: 4px;
}

.top-8 {
    margin-top: 8px;
}

.top-12 {
    margin-top: 12px;
}

.top-16 {
    margin-top: 16px;
}

.top-20 {
    margin-top: 20px;
}

.top-24 {
    margin-top: 24px;
}

.top-32 {
    margin-top: 32px;
}

.top-40 {
    margin-top: 40px;
}

.top-48 {
    margin-top: 48px;
}

.top-60 {
    margin-top: 60px;
}

.top-64 {
    margin-top: 64px;
}

.top-auto {
    margin-top: 10vh;
}

.right-4 {
    margin-right: 4px;
}

.right-8 {
    margin-right: 8px;
}

.right-16 {
    margin-right: 16px;
}

.right-20 {
     margin-right: 20px;
}

.right-24 {
    margin-right: 24px;
}

.right-32 {
    margin-right: 32px;
}

.bottom-4 {
    margin-bottom: 4px;
}

.bottom-10 {
    margin-bottom: 10px !important;
}

.bottom-16 {
    margin-bottom: 16px !important;
}

.bottom-20 {
    margin-bottom: 20px;
}

.bottom-32 {
    margin-bottom: 32px;
}

.bottom-64 {
    margin-bottom: 64px;
}

.left-4 {
    margin-left:4px;
}

.left-8 {
    margin-left: 8px;
}

.left-16 {
    margin-left: 16px !important;
}

.left-negative-auto {
    margin-left: -10vw !important;
}

.spinner {
    width: 100%; 
    display: flex; 
    justify-content: center; 
    align-items: center;
    height: 50px;
}

.level-pill {
    width: 67px;
    height: 20px;
    border-radius: 40px;
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    text-align: center;
    padding-top: 1px;
    margin: 20px 10px 0px 0px;
}

.text-with-wrap {
    width: 200px;
    font-size: 12px;
    line-height: 24px;
    color: $mainTextColor;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.filter-z-index {
    z-index: 1;
}

.link-color {
    color: $buxMainColor !important;
    text-decoration: underline;
}

.arrow-down {
    margin-left: 5px; 
    color: #000; 
    float: right;
}

.bold-text-size {
    font-size: 18px;
    line-height: 20px;
    font-weight: bold;
}

.large-bold-text {
    font-size: 24px;
    line-height: 30px;
    font-weight: bold;

    @media (max-width: 1360px) {
        font-size: 14px;
    }
}
.normal-text-size {
    font-size: 16px !important;
    line-height: 20px;
    font-weight: normal !important;

    @media (max-width: 1360px) {
        font-size: 12px;
    }
}

.icon-size {
    font-size: 21px;
    font-weight: bold;
}

.medium-font-weight {
    font-weight: 500 !important;
}

.response-normal-text {
    font-size: 0.6vw;
}

.responsive-bold-text {
    font-size: 0.8vw;
    font-weight: bold;
    line-height: 24px;
}

.title-semi-bold {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
}

.text-with-ellipsis {
    max-width: 150px;
    font-size: 12px;
    line-height: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.text-normal-with-ellipsis {
    background: red;
    display: inline-block;
    max-width: 150px;
    font-size: 16px;
    line-height: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.total-summary {
    width: 33.33%; 
    border: 1px solid #D1D5DD;
    display: flex; 
    justify-content: center; 
    padding: 5px 0px 5px 0px;
}

.admin-text-color {
    color: #909196;
    font-weight: 400px;
}

.modal-header-text {
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    color: #2B2D33;
}

.modal-sub-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #2b2d33;
}

.link-text {
    font-size: 16px;
    font-style: italic;
    word-wrap: break-word;

    @media (max-width: 1260px) {
        font-size: 12px;
    }
}

.arrow-width {
    font-size: 18px;
}

.placeholder-text-color {
    color: rgba(43, 45, 50, 0.8);
}

.icon-style {
    color: #2b2d33;
    font-size: 32px;
}

.active-check {
    width: 16px;
    height: 16px;
}

.code-size {
    font-size: 12px;
    font-weight: 400;
}

.min-size {
    font-size: 14px;
    font-weight: 400;
}


.hr-line {
    border-bottom: 1px solid #EEEEEE;
}

// responsive grid columns and breakpoints
// since MUI v4 does not support grid template areas
// used MUI v6 default breakpoints instead

.grid-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
}

.col-1  { grid-column: span 1; }
.col-2  { grid-column: span 2; }
.col-3  { grid-column: span 3; }
.col-4  { grid-column: span 4; }
.col-5  { grid-column: span 5; }
.col-6  { grid-column: span 6; }
.col-7  { grid-column: span 7; }
.col-8  { grid-column: span 8; }
.col-9  { grid-column: span 9; }
.col-10 { grid-column: span 10; }
.col-11 { grid-column: span 11; }
.col-12 { grid-column: span 12; }

@media (max-width: 599.98px) { /* md: Tablets */
    .col-xs-1  { grid-column: span 1; }
    .col-xs-2  { grid-column: span 2; }
    .col-xs-3  { grid-column: span 3; }
    .col-xs-4  { grid-column: span 4; }
    .col-xs-5  { grid-column: span 5; }
    .col-xs-6  { grid-column: span 6; }
    .col-xs-7  { grid-column: span 7; }
    .col-xs-8  { grid-column: span 8; }
    .col-xs-9  { grid-column: span 9; }
    .col-xs-10 { grid-column: span 10; }
    .col-xs-11 { grid-column: span 11; }
    .col-xs-12 { grid-column: span 12; }
}
  
@media (max-width: 899.98px) { /* sm: Mobile */
    .col-sm-1  { grid-column: span 1; }
    .col-sm-2  { grid-column: span 2; }
    .col-sm-3  { grid-column: span 3; }
    .col-sm-4  { grid-column: span 4; }
    .col-sm-5  { grid-column: span 5; }
    .col-sm-6  { grid-column: span 6; }
    .col-sm-7  { grid-column: span 7; }
    .col-sm-8  { grid-column: span 8; }
    .col-sm-9  { grid-column: span 9; }
    .col-sm-10 { grid-column: span 10; }
    .col-sm-11 { grid-column: span 11; }
    .col-sm-12 { grid-column: span 12; }
}
  
@media (max-width: 1199.98px) { /* md: Tablets */
    .col-md-1  { grid-column: span 1; }
    .col-md-2  { grid-column: span 2; }
    .col-md-3  { grid-column: span 3; }
    .col-md-4  { grid-column: span 4; }
    .col-md-5  { grid-column: span 5; }
    .col-md-6  { grid-column: span 6; }
    .col-md-7  { grid-column: span 7; }
    .col-md-8  { grid-column: span 8; }
    .col-md-9  { grid-column: span 9; }
    .col-md-10 { grid-column: span 10; }
    .col-md-11 { grid-column: span 11; }
    .col-md-12 { grid-column: span 12; }
}

@media (max-width: 1535.98px) { /* md: Tablets */
    .col-lg-1  { grid-column: span 1; }
    .col-lg-2  { grid-column: span 2; }
    .col-lg-3  { grid-column: span 3; }
    .col-lg-4  { grid-column: span 4; }
    .col-lg-5  { grid-column: span 5; }
    .col-lg-6  { grid-column: span 6; }
    .col-lg-7  { grid-column: span 7; }
    .col-lg-8  { grid-column: span 8; }
    .col-lg-9  { grid-column: span 9; }
    .col-lg-10 { grid-column: span 10; }
    .col-lg-11 { grid-column: span 11; }
    .col-lg-12 { grid-column: span 12; }
}