html { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
}

body{
  font-family: 'Inter'
}

@import './static/font-files/Inter Web/inter.css';

.sidebar::-webkit-scrollbar { width: 0 !important }
.sidebar { overflow: -moz-scrollbars-none; }
.sidebar { -ms-overflow-style: none; }

@font-face {
  font-family: 'Pixellari';
  src: local('Pixellari'), url(./static/fonts/Pixellari.ttf) format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./static/fonts/Montserrat.ttf) format('truetype');
}

.hyphens {
  -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


#terms::-webkit-scrollbar {
  width: 4px;
}

#terms::-webkit-scrollbar-track {
  border-radius: 10px;
}

#terms::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 142px;

}

#privacy::-webkit-scrollbar {
  width: 4px;
}

#privacy::-webkit-scrollbar-track {
  border-radius: 10px;
}

#privacy::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 142px;

}

#locations::-webkit-scrollbar {
  width: 6px;
}

#locations::-webkit-scrollbar-track {
  background-color: transparent;
}

#locations::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #B7B9C0;
}

.gm-style .gm-style-iw-c {
    font-family: 'Inter' !important;
    width: 350px;
    border-radius: 60px;
    background-color: #0e3d76;
}

.gm-style .gm-style-iw-d {
  font-family: 'Inter' !important;
  padding: 5px 15px 15px 10px;
  overflow: hidden !important;
}


.gm-style .gm-style-iw-t::after {
  background: #0e3d76 !important;
}

.gm-ui-hover-effect {
  display: none !important;
}

.strike {
  display: block;
  text-align: center;
  overflow: hidden;
  white-space: nowrap; 
}

.strike > span {
  position: relative;
  display: inline-block;
}

.strike > span:before,
.strike > span:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 99px;
  height: 1px;
  background: #909196;
}

.strike > span:before {
  right: 100%;
  margin-right: 15px;
}

.strike > span:after {
  left: 100%;
  margin-left: 15px;
}

.ant-progress-inner {
  background-color: #D4D5D8 !important;
}

.featured-promo {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none; 
  scroll-behavior: smooth; /* Firefox */
}
.featured-promo::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}


/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}


.container span {
  margin-top: 5px;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height:23px;
  width: 23px;
  border-radius: 4px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #F5922F;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}